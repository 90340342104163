import { useEffect, useState } from "react";

export const useWindowDimensions = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    let timeout;

    const handleResize = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        setWindowWidth(width);
        setWindowHeight(height);
        setIsMobile(width < 768);
      }, 100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { windowWidth, windowHeight, isMobile };
};
