const Privacy = () => {
  return (
    <div className="bg-white flex flex-col items-start text-left">
      <div className="container mx-auto pt-[3.2rem] pb-[6rem]">
        <section>
          <h1 className="md:text-[2.6rem] lg:text-[3.4rem] 2xl:text-[4.43rem] mb-[1rem] lg:mb-[1.8rem] 2xl:mb-[2.43rem]">
            Privacy Policy
          </h1>
          <h2 className="text-[1.25rem] md:text-[1.7rem] lg:text-[2rem] 2xl:text-[2.75rem] mb-[1.25rem] md:mb-[1rem]">
            Introduction
          </h2>

          <p className="text-[13px] md:text-[1rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.43rem]">
            This Privacy Policy ("Privacy Policy") describes the data protection
            practices of Derma Corp and its affiliates, including Dauchi
            (collectively, "Dauchi," "we," "our," or "us"), including when you
            visit any Dauchi website that links to this Privacy Policy
            (including www.dauchi.com) (collectively, our "Websites"); use any
            affiliated mobile applications (the "Apps") that link to or contain
            this Privacy Policy; or otherwise provide data to Dauchi. We refer
            to the Websites, Apps, and other services provided by Dauchi
            together in this Privacy Policy as the "Services." This Privacy
            Policy is incorporated into our Terms of Use. All capitalized terms
            used in this Privacy Policy but not defined herein have the meanings
            assigned to them in the Terms of Use.
            <br /> <br />
            <b>
              **PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE
              HANDLE YOUR INFORMATION. IF YOU DO NOT AGREE TO THIS PRIVACY
              POLICY, PLEASE DO NOT USE THE SERVICES.**
            </b>
            <br /> <br />
            <ul>
              <span className="mb-2 block">
                This Privacy Policy contains the following sections:
              </span>
              {[
                "The Information We Collect and the Sources of Such Information",
                "Purposes for How We Use Your Information",
                "Online Analytics and Advertising",
                "How We Disclose Your Information",
                "Your Rights and Choices",
                "Third Party Services and Notice About Health Information",
                "How We Protect Your Information",
                "Privacy Information for California Residents",
                "Privacy Information for Nevada Residents",
                "Retention of Your Information",
                "Children",
                "Revisions to Our Privacy Policy",
                "Contacting Us",
              ].map((item, index) => (
                <li className="list-disc list-inside" key={index}>
                  {item}
                </li>
              ))}
            </ul>
            <br />
            Let me know if you need any more changes or additional sections! The
            Information We Collect and the Sources of Such Information
            <br />
            We obtain information about you through the means discussed below
            when you use the Services. Please note that we need certain types of
            information so that we can provide the Services to you. If you do
            not provide us with such information, or ask us to delete it, you
            may no longer be able to access or use part or all of our Services.
          </p>
          <br />
          <br />
        </section>

        {/* Points */}
        <section>
          <p className="text-[13px] md:text-[1rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.43rem]">
            <b className="mb-2 block">1. Information You Provide to Us</b>
            <b className="mb-2 block">
              We collect a variety of information that you provide directly to
              us. For example, we collect information from you through:
            </b>
            <ul>
              {[
                "Account and product registration and administration of your account",
                "Processing your orders and requests for treatment, including orders and requests made by your provider in connection with your care",
                "Questions, communications, or feedback you submit to us via forms or email",
                "Your participation in research, surveys, contests, and sweepstakes",
                "Requests for customer support and technical assistance, including through online chat functionalities",
                "Uploads or posts to the Services",
                "Employment applications you submit",
              ].map((item, index) => (
                <li className="list-disc list-inside ml-2" key={index}>
                  {item}
                </li>
              ))}
            </ul>
            <br />
            The specific types of information we collect will depend upon the
            Services you use, how you use them, and the information you choose
            to provide. The types of data we collect directly from you include:
            <br />
            <br />
            <ul>
              {[
                "Identifiers, such as name, address, telephone number, date of birth, and email address",
                "Billing information, such as shipping address, credit or debit card number, verification number, and expiration date, collected by our payment processors on our behalf",
                "Commercial information, such as information about purchases or other transactions with us, including information about your healthcare provider, if applicable",
                "Customer service information, such as information about your customer service and maintenance interactions with us",
                "Demographic information such as your gender, age, marital status, and similar information in connection with the Services",
                "Information you provide in Apps, such as videos (and in some cases the recordings of such videos), photos, and signatures",
                "General geolocation information, such as city, state, or zip code",
                "Information about others, such as if you provide a family or friend's email address or contact information to allow access to your information or name them as an emergency contact",
                "User-generated content you post in public online forums on our Services or disclose to other users or your healthcare providers",
                "Sensitive Personal Information",
                "Health information, such as information about your symptoms, medical history, lifestyle, prescriptions, mental health, drug or alcohol use, genetics, treatment options, and relevant physical characteristics (e.g., your height and weight) as well as medical photos you upload, lab results, and your insurance information",
                "Information about your sex life and sexual orientation",
                "Log-in credentials, if you create an account",
                "Sensitive demographic data, such as race and ethnicity",
                "Identity verification information (e.g. driver's license or other government-issued ID card or number), and your signature (if required for notarization purposes)",
                "Contents of communications made via the Services",
                "Any other information you choose to directly provide to us in connection with your use of the Services.",
              ].map((item, index) => (
                <li className="list-disc list-inside ml-2" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </p>

          <br />
          <br />

          <p className="text-[13px] md:text-[1rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.43rem]">
            <b className="mb-2 block">
              2. Information We Collect Through Automated Means
            </b>
            We collect certain information about your use of the Services and
            the devices you use to access the Services, as described in this
            Section (“usage information”). As discussed further below, we and
            our service providers (which are third-party companies that work on
            our behalf), may use a variety of technologies, including cookies,
            SDKs, and similar tools, to assist in collecting this information.
            In some instances, such information may be considered sensitive
            personal information.
            <br />
            <br />
            <b className="mb-2 block">Our Websites</b>
            When you use our Websites, we collect and analyze information such
            as your IP address, browser types, browser language, operating
            system, the state or country from which you accessed the Services,
            software and hardware attributes (including device IDs) referring
            and exit pages and URLs, platform type, the number of clicks, files
            you download, domain names, landing pages, pages viewed and the
            order of those pages, the amount of time spent on particular pages,
            the terms you use in searches on our sites, the date and time you
            used the Services, error logs, and other similar information.
            <br />
            <br />
            <b className="mb-2 block"> Our Apps</b>
            When you use our Apps, we automatically receive certain information
            about the mobile phone, tablet, or computer used to access the Apps,
            including a mobile device identifier, advertising identifiers (in
            accordance with your device settings), IP address, operating system,
            version, Internet service provider, browser type, domain name and
            other similar information, whether and when you update the Apps,
            date and time of use, and how you use the Apps, including time spent
            in different portions of the Apps.
            <br />
            <br />
            <b className="mb-2 block"> Location Information</b>
            When you use the Services, we and our service providers may
            automatically collect general location information (e.g., IP
            address, city/state and or postal code associated with an IP
            address) from your computer or mobile device. This information
            allows us to enable access to content that varies based on a user's
            general location (e.g., to provide you with accurate sales tax
            information and to deliver content customized to your location).
            <br />
            <br />
            <b className="mb-2 block">
              {" "}
              Our Use of Cookies and Similar Online Tools
            </b>
            To collect the information discussed in this Section, we and our
            service providers use web server logs, cookies, tags, SDKs, tracking
            pixels, and other similar tracking technologies. We use these
            technologies to offer you a more tailored experience.
            <ul>
              {[
                "Web server log is a file where website activity is stored.",
                "SDK is a set of tools and/or code that we embed in our Apps and software to allow third parties to collect information about how users interact with the Services.",
                "Cookie is a small text file that is placed on your computer or mobile device when you visit a site, that enables us to: (i) recognize your computer/device; (ii) store your preferences and settings; (iii) understand the parts of the Services you have visited and used; (iv) enhance your user experience by delivering and measuring the effectiveness of content and advertising tailored to your interests; (v) perform searches and analytics; and (vi) assist with security and administrative functions.",
                "Tracking pixels (sometimes referred to as web beacons or clear GIFs) are tiny electronic tags with a unique identifier embedded in websites, online ads and/or email that are designed to: (1) collect usage information like ad impressions or clicks and email open rates; (2) measure popularity of the Services and associated advertising; and (3) access user cookies.",
              ].map((item, index) => (
                <li className="list-disc list-inside ml-2" key={index}>
                  {item}
                </li>
              ))}
            </ul>
            <br />
            <p>
              As we adopt additional technologies, we may also gather
              information through other methods.
            </p>
            <p>
              Please note that you can change your settings to notify you when a
              cookie is being set or updated, or to block cookies altogether.
              Please consult the "Help" section of your browser for more
              information (e.g., Internet Explorer; Google Chrome; Mozilla
              Firefox; or Apple Safari). Please note that by blocking,
              disabling, or managing any or all cookies, you may not have access
              to certain features or offerings of the Services.
            </p>
          </p>

          <br />
          <br />

          <p className="text-[13px] md:text-[1rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.43rem]">
            <b className="mb-2 block">
              3. Information We Collect From Social Media and Other Content
              Platforms
            </b>
            <p>
              When you "like" or "follow" us on Facebook, Instagram, Twitter, or
              other social media platforms, we may collect some information from
              you including your name, email address, and any comments or
              content you post relevant to us. We also collect your information
              if you sign up for one of our promotions or submit information to
              us through social media platforms.
            </p>
            <br />
            <p>
              If you access the Services through a third-party connection or
              log-in (e.g., through a social network or third-party
              authentication tool), you may allow us to have access to and store
              certain information from such third parties depending on your
              settings on such services. If you do not wish to have this
              information disclosed, do not use these connections to access the
              Services. For a description of how these third parties handle your
              information, please refer to their privacy policies and terms of
              use, which may permit you to modify your privacy settings.
            </p>
            <br />
            <p>
              Similarly, if you choose to connect your App to third-party
              platforms or tools, or with other apps on your device (e.g.,
              health or fitness monitoring platforms or tools, or your contacts,
              photos, or calendar), such third parties and tools may allow us to
              have access to and store additional information as it relates to
              your use of the Services (e.g., to integrate your fitness data
              within the Services or access your calendar to help you schedule
              an appointment). If you do not wish to have this information
              disclosed, do not initiate these connections.
            </p>
          </p>

          <br />
          <br />

          <p className="text-[13px] md:text-[1rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.43rem]">
            <b className="mb-2 block">
              4. Information We Receive From Other Sources
            </b>
            <p>
              We work closely with third parties (including, for example, third
              party intermediaries, such as physicians, medical professionals,
              and pharmacies to provide you with the Services, as well as with
              advertising networks, analytics providers, marketing partners, and
              search information providers). Such third parties will sometimes
              provide us with additional information about you.
            </p>
            <br />
            <p>Purposes for How We Use Your Information</p>
            <br />
            <p>
              In connection with providing you with the Services, we may use
              your information for the following business purposes:
            </p>
            <br />
            <p>Provide and Manage the Services</p>
            <ul>
              {[
                "Carry out, improve, and manage the Services and, as applicable, facilitate the provision of health care services to you by physicians or other health care providers and ensure that the physicians or health care providers have the services and support necessary for health care operations.",
                "Provide you with technical support and customer service, and troubleshoot any technical issues or errors.",
                "Communicate with you about the Services, your use of the Services, or your inquiries related to the Services and send you communications on behalf of physicians or other health care providers utilizing the Services to meet your needs.",
                "Verify your identity and administer your account, including processing your payments, fulfilling your orders, and verifying the authenticity of your government-issued identification photo.",
              ].map((item, index) => (
                <li className="list-disc list-inside ml-2" key={index}>
                  {item}
                </li>
              ))}
            </ul>
            <br />
            <p>Analyze and Improve the Services</p>
            <ul>
              {[
                "Engage in internal research to understand the effectiveness of our Services, improve our Services, and better understand our user base. If we publish or provide the results of this research to others, such research will be presented in a de-identified and aggregate form such that individual users cannot be identified.",
                "Ensure that content from our Services is presented in the most effective manner for you and for your computer or device, allow you to participate in interactive features of our Services (when you choose to do so), and as part of our efforts to keep our Services safe and secure.",
                "Help us better understand your interests and needs, such as by engaging in analysis and research regarding use of the Services.",
              ].map((item, index) => (
                <li className="list-disc list-inside ml-2" key={index}>
                  {item}
                </li>
              ))}
            </ul>
            <br />
            <p>Advertising and Marketing</p>
            <ul>
              {[
                "Communicate with you (in accordance with applicable legal requirements) by email, postal mail, or phone about surveys, promotions, special events or our products and Services and those of our subsidiaries, affiliates, and parent companies and any of their related businesses and those of our third-party partners.",
                "Provide you (in accordance with applicable legal requirements) with more relevant advertisements and personalized content, and measure or understand the effectiveness of advertising and content we serve to you and others, and to deliver and customize relevant advertising and content to you.",
              ].map((item, index) => (
                <li className="list-disc list-inside ml-2" key={index}>
                  {item}
                </li>
              ))}
            </ul>
            <br />
            <p>Legal Purposes</p>
            <ul>
              {[
                "Comply in good faith with any procedures, laws, and regulations which apply to us where it is necessary for our legitimate interests or the legitimate interests of others.",
                "Establish, exercise, or defend our legal rights where it is necessary for our legitimate interests or the legitimate interests of others, such as protecting against malicious, fraudulent, or illegal activity.",
              ].map((item, index) => (
                <li className="list-disc list-inside ml-2" key={index}>
                  {item}
                </li>
              ))}
            </ul>
            <br />
            <p>
              If you are a resident of California or Colorado and want
              additional details about the purposes for which we use each
              category of information we collect from and about you, please
              click here.
            </p>
            <br />
            <p>Combined Information</p>
            <br />
            <p>
              For the purposes discussed in this Privacy Policy, we may combine
              the information that we collect through the Services with
              information that we receive from other sources, both online and
              offline, and use and disclose such combined information in
              accordance with this Privacy Policy.
            </p>
            <br />
            <p>Aggregate/De-Identified Data</p>
            <br />
            <p>
              We may aggregate and/or de-identify any information collected
              through the Services so that such information can no longer be
              linked to you or your device ("Aggregate/De-Identified
              Information"). We may use Aggregate/De-Identified Information for
              any purpose, including for research and marketing purposes, and
              may also disclose such data to any third parties, including
              advertisers, promotional partners, and sponsors.
            </p>
            <br />
            <p>Online Analytics and Advertising</p>
            <br />

            <br />
            <p>1. Online Analytics</p>
            <br />
            <p>
              We may use third-party web analytics services (such as those of
              Google Analytics -including Google Signals, Google User-ID, and
              other Google Analytics features- Metabase, Amplitude, and
              MixPanel) on our Services to collect and analyze usage information
              through cookies and similar tools; engage in auditing, research,
              or reporting; assist with fraud prevention; try to locate the same
              unique users across multiple browsers or devices to better tailor
              services and features; and provide certain features to you. If you
              have a Google account with personalized advertising enabled,
              through Google Signals, Google will also be able to gather for us
              analytics and engagement information from across the various
              devices you use to access the Services. To prevent Google from
              using your information for analytics (including cross-device
              tracking for personalization purposes), you may install the Google
              Analytics Opt-out Browser Add-on by clicking here. And to opt out
              of Google Signals, please open your "Settings" app, locate and tap
              "Google," select "Ads," and turn ON "Opt out of Ads
              Personalization." You may also be able to disable cross-device
              tracking through your Android or Apple device-based settings.
            </p>
            <br />
            <p>
              If you receive email from us, we may use certain analytics tools,
              such as clear GIFs to capture data such as when you open our
              message or click on any links or banners our email contains. This
              data allows us to gauge the effectiveness of our communications
              and marketing campaigns.
            </p>

            <br />
            <p> 2. Online Advertising</p>
            <br />
            <p>
              The Services may integrate third-party advertising technologies
              (e.g., ad networks and ad servers such as Facebook, Google
              AdWords, TikTok, Criteo and others) that use cookies, pixels, and
              other technologies to deliver relevant content and advertising for
              Dauchi products and research on the Services, as well as on other
              websites you visit and other applications you use. The ads may be
              based on various factors such as the content of the page you are
              visiting, information you enter such as your searches, demographic
              data, and other information we collect from you. These ads may be
              based on your current activity or your activity over time and
              across other websites and online services.
            </p>
            <br />
            <p>
              We sometimes provide our customer information (such as email
              addresses) to service providers, who may "match" this information
              in de-identified form to cookies (or mobile ad identifiers) and
              other proprietary IDs, in order to provide you with more relevant
              ads when you visit other websites and mobile applications. If you
              began using the Services after January 1,2024, we may also
              disclose customer information such as your email address with
              third-party advertising networks that use such information to
              better target advertising to you regarding Dauchi products and
              services, as well as other products and services, as you browse
              the internet.
            </p>
            <br />
            <p>
              If you are interested in more information about tailored browser
              advertising and how you can generally control cookies from being
              put on your computer to deliver tailored advertising, you may
              visit the Network Advertising Initiative's Consumer Opt-Out link,
              the Digital Advertising Alliance's Consumer Opt-Out link, or Your
              Online Choices to opt-out of receiving tailored advertising from
              companies that participate in those programs. If you are
              interested in more information about Criteo's privacy practices or
              opting-out of Criteo's advertising services, you may view their
              Privacy Policy. To opt out of Google Analytics for display
              advertising or customize Google display network ads, visit the
              Google Ads Settings page. To update your advertising preferences
              with Facebook, click here. To update your advertising preferences
              with TikTok, click here. We do not control these opt-out links,
              whether these opt-out links change over time, or whether any
              particular company chooses to participate in the industry opt-out
              programs listed above. We are not responsible for any choices you
              make using these mechanisms or the continued availability or
              accuracy of these mechanisms.
            </p>
            <br />
            <p>
              For additional ways to opt out of online advertising activities,
              please see the "Your Rights and Choices" section below.
            </p>
            <br />
            <p>
              Please note that if you exercise the opt out choices above, you
              will still see advertising when you use the Services, but it will
              not be tailored to you based on your online behavior over time.
            </p>

            <br />
            <p> 3. Mobile Advertising</p>
            <br />
            <p>
              When using our Apps or others, you may also receive tailored
              in-application advertisements. We may use third-party service
              providers to deliver advertisements on mobile applications or for
              mobile application analytics. Each operating system, iOS for Apple
              phones, Android for Android devices, and Windows for Microsoft
              devices provides its own instructions on how to prevent the
              delivery of tailored in-application advertisements. We do not
              control how the applicable platform operator allows you to control
              receiving personalized in-application advertisements; thus, you
              should contact the platform provider for further details on opting
              out of tailored in-application advertisements. You may review the
              support materials and/or the device settings for the respective
              operating systems to opt-out of tailored in-app advertisements.
            </p>

            <br />
            <p>4. Notice Concerning Do Not Track</p>
            <br />
            <p>
              Do Not Track ("DNT") is a privacy preference that users can set in
              certain web browsers. We are committed to providing you with
              meaningful choices about the information collected on our Websites
              for third-party purposes, and that is why we provide the variety
              of opt-out mechanisms listed above. However, we do not currently
              recognize or respond to browser-initiated DNT signals. To learn
              more about Do Not Track, you can do so here. Please note that “Do
              Not Track” is a distinct privacy mechanism from the browser-based
              opt out signals referenced below in the "Your Rights and Choices"
              section, which Dauchi does honor in accordance with applicable
              law.
            </p>
            <br />
            <p>How We Disclose Your Information</p>
            <br />
            <p>
              We may disclose your information for our business purposes in the
              following ways:
            </p>
            <br />
            <p>Affiliates and Subsidiaries</p>
            <br />
            <p>
              We may disclose information we collect within any Dauchi member or
              group (i.e., our subsidiaries and affiliates, including our
              ultimate holding company, Derma Corp, and its subsidiaries) to
              deliver products and services to you, ensure a consistent level of
              service across our products and services, and enhance our
              products, services, and your customer experience.
            </p>
            <br />
            <p>Health Care Providers and Services</p>
            <br />
            <p>
              We disclose your information to health care providers: (i) to
              schedule and fulfill appointments and provide health care services
              as part of the Services, (ii) to whom you send messages through
              our Services, and (iii) for other treatment, payment or health
              care operations purposes, including pharmacy services, upon your
              request.
            </p>
            <br />
            <p>Service Providers</p>
            <br />
            <p>
              We provide access to or disclose your information to select third
              parties who use the information to perform services on our behalf.
              They provide a variety of services to us, including billing,
              content/service enhancements, partner labs, sales, marketing,
              advertising, analytics, research, customer service, shipping and
              fulfillment, data hosting and storage, IT and security, fraud
              prevention, payment processing, and auditing, consulting, and
              legal services. These entities may also include health care
              organizations, pharmacies, and other third parties we use to
              support our business or in connection with the administration and
              support of the Services.
            </p>
            <br />
            <p>Advertising Networks</p>
            <br />
            <p>
              Please see the "Online Advertising" section above for details
              about how we disclose information to advertising partners.
            </p>
            <br />
            <p>Joint Product/Marketing Partners</p>
            <br />
            <p>
              These are entities that jointly offer or provide services or
              products with us. These entities collect and use data in
              accordance with their own terms and privacy policies.
            </p>
            <br />
            <p>Protection of Dauchi and Others</p>
            <br />
            <p>
              By using the Services, you acknowledge and agree that we may
              access, retain and disclose the information we collect and
              maintain about you if required to do so by law or in a good faith
              belief that such access, retention or disclosure is reasonably
              necessary to: (a) comply with legal process (e.g. a subpoena or
              court order); (b) enforce our Terms of Use, this Privacy Policy,
              or other contracts with you, including investigation of potential
              violations thereof; (c) respond to claims that any content
              violates the rights of third parties; (d) respond to your requests
              for customer service; and/or (e) protect the rights, property or
              personal safety of Dauchi, its agents and affiliates, its users
              and/or the public. This includes exchanging information with other
              companies and organizations for fraud protection, and spam/malware
              prevention, and similar purposes.
            </p>
            <br />
            <p>Business Transfers</p>
            <br />
            <p>
              As we continue to develop our business, we may buy, merge, or
              partner with other companies. In such transactions, (including in
              contemplation of such transactions) user information may be among
              the transferred assets. If a portion or all of our assets are sold
              or transferred to a third party, customer information (including
              your email address) would likely be one of the transferred
              business assets. If such transfer is subject to additional
              mandatory restrictions under applicable laws, we will comply with
              such restrictions.
            </p>
            <br />
            <p>Public Forums/User Interactions</p>
            <br />
            <p>
              Certain features of our Services make it possible for you to
              disclose comments publicly or with other users. Any information
              that you post publicly is not confidential, and we may use it for
              any purpose (including in testimonials or other marketing
              materials). For example, if you submit a product review on one of
              our Websites, we may display your review (along with the name
              provided, if any) on other Dauchi Websites and on third-party
              websites. Any information you post openly in these ways will be
              available to the users you disclosed it to and potentially the
              public at large and may be accessible through third-party search
              engines. Accordingly, please take care when using these features.
              We are not responsible for how others use the information about
              you that you disclose to them through the Services.
            </p>
            <br />
            <p>Consent</p>
            <br />
            <p>
              We may also disclose your information in other ways you direct us
              to and when we have your consent.
            </p>
            <br />
            <p>Aggregate/De-Identified Information</p>
            <br />
            <p>
              We reserve the right to create Aggregate/De-Identified Data from
              the information we collect through the Services and our disclosure
              of such Aggregate/De-Identified Data is in our discretion.
            </p>
            <br />
            <p>Your Rights and Choices</p>
            <br />
            <p>
              Depending on the state in which you live, you may have legal
              rights with respect to your information. The types of requests you
              may be able to make are as follows:
            </p>
            <ul>
              {[
                "Information about the categories of information we process, our uses of your information, our sources of such information, and our disclosure of such information",
                "Access to the information that Dauchi has collected about you and a copy of certain information in a portable format",
                "Correct certain information we have about you",
                "Deletion of the information we have about you",
              ].map((item, index) => (
                <li className="list-disc list-inside ml-2" key={index}>
                  {item}
                </li>
              ))}
            </ul>
            <br />
            <p>
              You may make a request by clicking emailing support@dauchi.com to
              submit your request. Before we disclose, correct, or delete
              information in response to any of these requests, we will need to
              verify your identity. Depending on the nature of your request, we
              may contact you for further information if appropriate to verify
              your identity. Note, however, that Dauchi will never ask you for
              sensitive personal or financial information when verifying your
              identity, and no Dauchi employee will ask you to tell them your
              password. If you are an authorized agent submitting a request on
              their behalf, we may require proof of the written authorization
              you have received before processing the request.
            </p>
            <br />
            <p>
              Certain information may be exempt from such requests under
              applicable law. For example, if the request prevents us from
              completing a transaction you initiated, interferes with our legal
              obligations, affects legal matters including a Dauchi user's
              rights to data contained in their account, we cannot verify your
              identity, or compliance with your request is not legally required
              and would involve disproportionate cost or effort. But in any
              event, we will respond to your request within a reasonable
              timeframe and provide you with an explanation. If we deny your
              request in whole or in part, the laws in your jurisdiction may
              provide you with rights to appeal our response. If applicable, we
              will provide you with information about your appeal options in our
              response to you.
            </p>
            <br />
            <p>
              Dauchi will not discriminate against anyone that makes a rights
              request, but in some cases, we will not be able to provide our
              Services to you without that information.
            </p>
            <br />
            <p>
              Depending on applicable law, you may have the right to appeal our
              decision to deny your request, if applicable. We will provide
              information about how to exercise that right in our response
              denying the request. You also may have the right to lodge a
              complaint with a supervisory authority.
            </p>
            <br />
            <p>
              Opt out of sale of and disclosure of information for online
              targeted advertising
            </p>
            <br />
            <p>
              As explained in the Online Analytics and Advertising section
              above, and subject to applicable laws as discussed further below,
              Dauchi allows third parties to receive certain information such as
              cookies, IP address, device identifiers, hashed contact
              information, browsing behavior, and/or other activity to enable
              the delivery of targeted advertising to you. These activities may
              qualify as the “sale” of personal information or "sharing" or
              processing of personal information for targeted advertising, as
              defined in applicable law.
            </p>
            <br />
            <p>
              If you have a legally recognized browser-based opt-out preference
              signal turned on via your device browser (such as Global Privacy
              Control), we recognize such preference in accordance and to the
              extent required by applicable law. Note, if you use a cookie
              blocker such as Ghostery, it may block the visibility of this
              tool. You can also use an authorized agent to submit a request to
              opt-out on your behalf if you provide the agent with written
              permission to do so. We may require the agent to submit proof that
              you have authorized them to submit an opt-out request.
            </p>
            <br />
            <p>
              In addition to advertising activities using cookies and pixels, we
              may also share user email addresses with advertising partners to
              provide you with more relevant advertising. To opt out of this
              activity, please email support@dauchi.com
            </p>
            <br />
            <p>
              If you opt out, you may still receive advertising, it just may not
              be tailored to your interests. Please note, if you use different
              browsers, devices, or services you will need to opt out on each
              browser or device where you want your choice to apply.
            </p>
            <br />
            <p>Sensitive Data Opt-Out/Opt-In Right</p>
            <br />
            <p>
              To provide you with more relevant online advertising regarding
              Dauchi's products and services, as described immediately above, we
              may use information about the treatments you have requested or
              received and similar information. This may include the processing
              of information that is considered "sensitive data" or "sensitive
              personal information" under applicable laws, such as health
              information and information about sex life and sexual orientation
              (collectively referred to herein as "sensitive data"). You have a
              right to limit our use of sensitive data for purposes other than
              to provide the services or goods you request or as otherwise
              permitted by law, including our and our partner's processing of
              sensitive data for tailored advertising purposes. To opt-out from
              such additional purposes, please visit the “Your Privacy Choices”
              page or use the Global Privacy Control described above. Depending
              on your jurisdiction, we may ask for your consent before using
              your sensitive data. If you consent and subsequently change your
              mind, you can opt out on the "Your Privacy Choices" page above.
              For more information about our use and disclosure of sensitive
              data, click [here].
            </p>
            <br />
            <p>Marketing preferences</p>
            <br />
            <p>
              You may instruct us not to use your contact information to contact
              you by email, postal mail, or phone regarding products, services,
              promotions, and special events that might appeal to your interests
              by contacting us using the information below. In commercial email
              messages, you can also opt out by following the instructions
              located at the bottom of such emails. Please note that, regardless
              of your request, we may still use and disclose certain information
              as permitted by this Privacy Policy or as required by applicable
              law. For example, you may not opt out of certain operational
              emails, such as those reflecting our relationship or transactions
              with you.
            </p>
            <br />
            <p>Third Party Services and Notice About Health Information</p>
            <br />
            <p>
              This Privacy Policy does not address, and we are not responsible
              for, the privacy, information, or other practices, including data
              privacy and security processes and standards of any third parties,
              including physicians and other health care providers using the
              Services, the manufacturer of your mobile device and other IT
              hardware and software, and any other third party mobile
              application, website, or service to which our Services may contain
              a link. These third parties may at times gather information from
              or about you. We have no control over the privacy practices of
              these third parties. The collection, use, and disclosure of your
              information will be subject to the privacy policies of the
              third-party websites or services, and not this Privacy Policy. We
              urge you to read the privacy and security policies of these third
              parties.
            </p>
            <br />
            <p>How We Protect Your Information</p>
            <br />
            <p>
              Dauchi takes a variety of technical and organizational security
              measures to protect your information against accidental or
              unlawful destruction or accidental loss, alteration, unauthorized
              disclosure, or access. However, no method of transmission over the
              Internet, and no means of electronic or physical storage, is
              absolutely secure. As such, you acknowledge and accept that we
              cannot guarantee the security of your information transmitted to,
              through, or on our Services or via the Internet and that any such
              transmission is at your own risk. Where we have given you (or
              where you have chosen) a password that enables you to access the
              Services, you are responsible for keeping this password
              confidential. We ask you not to provide your password to anyone.
              The information you disclose in public areas may be viewed by
              anyone.
            </p>
            <br />
            <p>Privacy Information for California Residents</p>
            <br />
            <p>
              If you are a California resident, the California Consumer Privacy
              Act (“CCPA”) requires us to provide you with the following
              additional information about: (1) the purpose for which we use
              each category of “personal information” and “sensitive personal
              information” (as defined in the CCPA) we collect; and (2) the
              categories of third parties to which we (a) disclose such
              information for a business purpose, (b) “share” information for
              “cross-context behavioral advertising,” and/or (c) “sell” such
              information. Under the CCPA, “sharing” is defined as the targeting
              of advertising to a consumer based on that consumer’s personal
              information obtained from the consumer’s activity across websites,
              and “selling” is defined as the disclosure of personal information
              to third parties in exchange for monetary or other valuable
              consideration. We “share” information to provide more relevant and
              tailored advertising to you regarding our Services. Our use of
              third-party analytics services and online advertising services may
              result in the sharing of online identifiers (e.g., cookie data, IP
              addresses, device identifiers, and usage information) in a way
              that may be considered a “sale” under the CCPA. Please see the
              following chart for the rest of this information:
            </p>
            <br />
            <p>
              For more detailed information about how we use and disclose your
              personal information, please see the "Purposes for How We Use Your
              Information" and "How We Disclose Your Information" sections of
              this Policy above. Please note that the ways we use your
              information may also differ depending on applicable legal
              obligations, such as where you opt out of a particular use, we
              will no longer use your information for that purpose.
            </p>
            <br />
            <p>
              Your Choices Regarding “Sharing” and “Selling”: As described more
              in the "Your Rights and Choices" section of our Privacy Policy,
              you have the right to opt out of Dauchi’s sale/sharing of your
              personal information for purposes of online advertising and
              related purposes through the use of cookies, pixels, and similar
              online tools by clicking here. Please note that we will also honor
              legally-required browser-based opt-out signals (such as the Global
              Privacy Control) in accordance with our legal obligations.
            </p>
            <br />
            <p>
              In addition to advertising activities using cookies and pixels, we
              may also share user email addresses with advertising partners to
              provide you with more relevant advertising. To opt out of this
              activity, please complete this form.
            </p>
            <br />
            <p>
              We do not knowingly “sell” or “share” the personal information of
              children under 16.
            </p>
            <br />
            <p>Other CCPA Rights</p>

            <br />
            <p>Incentive Programs</p>
            <br />
            <p>
              The Services may offer opportunities to receive certain services
              or benefits such as gift cards, discounts, or free services, which
              may require the provision of Personal Information. Such
              opportunities could be considered a financial incentive under
              applicable law (each, an “Incentive Program”). When you
              participate in an Incentive Program, you agree to the terms of
              that Incentive Program, and may revoke your participation
              depending on the Incentive Program (e.g., by opting out of
              emails). To the extent we can determine the value of your
              information, we consider the value of providing you with a more
              personalized experience as well as the expenses we incur in
              providing Incentive Programs that are reasonably related to the
              costs associated with offering the Incentives.
            </p>
            <br />
            <p> Right to Limit</p>
            <br />
            <p>
              The CCPA also allows you to limit the use or disclosure of your
              “sensitive personal information” (as defined in the CCPA) if your
              sensitive personal information is used for certain purposes. You
              have a right to limit our use of sensitive personal information
              for any purposes other than to provide the services or goods you
              request or as otherwise permitted by law (for example, you have
              the right to opt out of our use of information about the Dauchi
              treatments you have received to opt out of receiving targeted
              online advertisements about similar products and services that
              might be of interest to you). To opt-out from such additional
              purposes, please visit the “Your Privacy Choices” page or use the
              Global Privacy Control described in the “Your Rights and Choices”
              section of this statement.
            </p>
            <br />
            <p>Other information rights</p>
            <br />
            <p>
              Please see the “Your Rights and Choices” section of our Privacy
              Policy above for information about the additional rights you have
              with respect to your personal information under California law and
              how to exercise them.
            </p>
            <br />
            <p>Retention of Your Personal Information</p>
            <br />
            <p>Please see the “Retention of Your Information” section below.</p>
            <br />
            <p>Shine the Light Disclosure</p>
            <br />
            <p>
              The California "Shine the Light" law gives residents of California
              the right under certain circumstances to request information from
              us regarding the manner in which we disclose certain categories of
              personal information (as defined in the Shine the Light law) with
              third parties for their direct marketing purposes. We do not
              disclose your personal information to third parties for their own
              direct marketing purposes.
            </p>
            <br />
            <p>Privacy Information for Nevada Residents</p>
            <br />
            <p>
              Under Nevada law, certain Nevada consumers may opt out of the sale
              of "personally identifiable information" for monetary
              consideration (as such terms are defined under Nevada law) to a
              person for that person to license or sell such information to
              additional persons. We do not engage in such activity; however, if
              you are a Nevada resident who has purchased services from us, you
              may submit a request to opt out of any potential future sales
              under Nevada law by emailing support@dauchi.com. Please note we
              will take reasonable steps to verify your identity and the
              authenticity of the request. Once verified, we will maintain your
              request in the event our practices change.
            </p>
            <br />
            <p>Retention of Your Information</p>
            <br />
            <p>
              We keep your information for no longer than necessary for the
              purposes for which it is processed. The length of time for which
              we retain information depends on the purposes for which we
              collected and use it, the nature and sensitivity of the
              information, the potential risk of harm from unauthorized use or
              disclosure of the information, the purposes for which we process
              the information, and our legitimate interests, and/or as required
              to comply with applicable laws.
            </p>
            <br />
            <p>Children</p>
            <br />
            <p>
              We do not knowingly collect personal data from anyone under the
              age of 13 through our Services, and our Services are not directed
              to children under the age of 13. If we discover we have received
              any “personal information” (as defined under the Children’s Online
              Privacy Protection Act) from a child under the age of 13 in
              violation of this Privacy Policy, we will take reasonable steps to
              delete that information as quickly as possible.
            </p>
            <br />
            <p>Revisions to Our Privacy Policy</p>
            <br />
            <p>
              We reserve the right to change this Privacy Policy at any time to
              reflect changes in the law, our data collection and use practices,
              the features of our Services, or advances in technology. We will
              make the revised Privacy Policy accessible through the Services,
              so you should review it periodically. The date this Privacy Policy
              was last revised is identified at the top of the document. You are
              responsible for periodically monitoring and reviewing any updates
              to the Privacy Policy. If we make a material change to the Privacy
              Policy, we will provide you with appropriate notice in accordance
              with legal requirements. Your continued use of our Websites or
              Apps after such amendments (and notice, where applicable) will be
              deemed your acknowledgment of these changes to this Privacy
              Policy.
            </p>
            <br />
            <p>Protected Health Information (PHI)</p>
            <br />
            <p>
              Dauchi complies with all applicable federal and state laws, including the Health Insurance
              Portability and Accountability Act (HIPAA), to safeguard Protected Health Information (PHI).
              We ensure the confidentiality, integrity, and security of PHI by implementing administrative,
              technical, and physical safeguards. PHI is only accessed, used, or disclosed to provide medical
              treatment, manage healthcare operations, process payments, or as required by law. Unauthorized
              access or sharing of PHI is strictly prohibited.
            </p>
            <br />
            <p>
              Patient Rights Regarding Protected Health Information
            </p>
            <br />
            <p>
              Patients have the following rights concerning their PHI:
            </p>
            <ol>
              {[
                "Access: Patients can request access to their medical records and PHI.",
                "Amendment: Patients can request corrections to their PHI if they believe it is inaccurate or incomplete.",
                "Restrictions: Patients can request restrictions on certain uses or disclosures of their PHI.",
                "Confidential Communications: Patients can request that communications regarding PHI are sent through specific methods or to specific locations.",
                "Accounting of Disclosures: Patients have the right to request an accounting of certain disclosures of their PHI made by Dauchi.",
                "Complaints: Patients can file a complaint if they believe their privacy rights have been violated"
              ].map((item, index) => {
                const [firstWord, ...rest] = item.split(" ");
                return (
                <li className="list-decimal list-inside ml-2" key={index}>
                  <strong>{firstWord}</strong> {rest.join(" ")}
                </li>
                );
              })}
            </ol>
            <p>To exercise any of these rights, patients may contact us using the information provided below.</p>
            <br />
            <p>Privacy Official Contact Information</p>
            <br />
            <p>Dauchi's designated Privacy Official is responsible for overseeing compliance with privacy policies and practices. For any questions, concerns, or requests related to PHI or this Privacy Policy, please contact:</p>
            <br />
            <p>Jody Miller </p>
            <p>Privacy Official</p>
            <p>Email: jody@dauchi.com</p>
            <br />
            <p>Contacting Us</p>
            <br />
            <p>If you have any questions about this Privacy Policy or Dauchi's privacy practices, please contact us at:</p>
            <br />
            <p>Dauchi</p>
            <p>7216 Varna Avenue</p>
            <p>North Hollywood, CA 91605</p>
            <p>Support@dauchi.com</p>
          </p>
        </section>
      </div>
    </div>
  );
};

export default Privacy;
