import React, { useState } from "react";
import { Logo } from "../logo/Logo";
import { Link } from "react-router-dom";
import { DAUCHI_EMAIL, DAUCHI_SUPPORT_EMAIL } from "../../constants/Common";

export const DesktopFooter = ({ treatments, resources, aboutUs, icons }) => {
  const [seeAll, setSeeAll] = useState(false);
  const [email, setEmail] = useState("");

  const updatedTreatments = seeAll ? treatments : treatments.slice(0, 5);

  const handleSubscribe = () => {};

  return (
    <div
      id="footer-container"
      className="bg-darkPrimary flex w-full font-subHeadline"
    >
      <div
        id="footer-internal-container"
        className="my-10 mx-10 items-center w-full flex flex-col justify-between"
      >
        <div className="flex w-full md:mb-12 lg:mb-28">
          <div className="flex flex-1 flex-col justify-between">
            <div id="logo-section" className="flex flex-col text-start">
              <Logo />
            </div>
            <div id="contact-section" className="text-start">
              <div className="text-secondary font-bold md:text-[25px] lg:text-[30px] 2xl:text-[35px]">
                Say Hello
              </div>
              <div className="text-secondary underline  md:text-[12px] lg:text-[16px] 2xl:text-[20px]">
                {DAUCHI_EMAIL}
              </div>
              <div className="text-white md:text-[12px] lg:text-[16px] 2xl:text-[20px] mt-2">
                Non-medical customer service at
              </div>
              <div className="text-secondary underline md:text-[12px] lg:text-[16px] 2xl:text-[20px] mb-2">
                {DAUCHI_SUPPORT_EMAIL}
              </div>
              <div className="text-white md:text-[12px] lg:text-[16px] 2xl:text-[20px]">
                9:30a - 6:30p EST M-F
              </div>
              <div className="text-white md:text-[12px] lg:text-[16px] 2xl:text-[20px]">
                Phone Number: (424) 372-7851
              </div>
              <div className="text-white md:text-[12px] lg:text-[16px] 2xl:text-[20px]">
                Address: 7216 Varna Ave
              </div>
              <div className="text-white md:text-[12px] lg:text-[16px] 2xl:text-[20px]">
                North Hollywood, CA 91605
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 2xl:ml-0 custom:ml-20 text-start">
            <div id="treatments-section" className="md:mb-16 lg:mb-20">
              <h1 className="text-secondary md:text-[13px] lg:text-[16px] 2xl:text-[18px] mt-4">
                Treatments
              </h1>
              <Navlinks navList={updatedTreatments} useLink={true} />
              {!seeAll && (
                <p
                  className="text-white mt-1 md:text-[14px] lg:text-[16px] 2xl:text-[20px]"
                  onClick={() => {
                    setSeeAll(true);
                  }}
                >
                  See All
                </p>
              )}
            </div>
            <div id="resources-section" className="flex flex-col text-white">
              <h1 className="text-secondary md:text-[13px] lg:text-[16px] 2xl:text-[18px]">
                Resources
              </h1>
              <Navlinks navList={resources} useLink={true} />
            </div>
          </div>
          <div className="flex flex-1 flex-col text-start ml-0 custom:ml-20 mt-4 font-subHeadline justify-between">
            <div id="about-us-section" className="flex flex-col">
              <h1 className="text-secondary md:text-[13px] lg:text-[16px] 2xl:text-[18px]">
                About Us
              </h1>
              <Navlinks navList={aboutUs} />
              <a href={`mailto:${DAUCHI_SUPPORT_EMAIL}`}>
                <p className="text-white md:text-[14px] lg:text-[16px] 2xl:text-[20px] mt-1">
                  Email us
                </p>
              </a>
            </div>
            <div
              id="signup-section"
              className="flex flex-col text-white text-md"
            >
              <h1 className="text-secondary md:text-[16px] lg:text-[20px] 2xl:text-[24px] mb-1">
                Sign Up for Updates
              </h1>
              <p className=" md:text-[12px] lg:text-[16px] 2xl:text-[20px] mb-4">
                Enter your email to receive updates from Dauchi
              </p>
              <div>
                <input
                  type="email"
                  placeholder="Your Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="text-black md:w-[8.5rem] lg:w-[12rem] xl:w-[15rem] 2xl:w-[17.2rem] md:h-[1.5rem] lg:h-[2.19rem] p-3 md:text-[10px] lg:text-[12px] 2xl:text-[16px]"
                />
                <button
                  className="md:ml-2 lg:ml-4 2xl:ml-8 md:text-[10px] lg:text-[14px] xl:text-[16px] 2xl:text-[20px] md:w-[5rem] lg:w-[6rem] xl:w-[9rem] 2xl:w-[10.75rem] text-darkPrimary bg-secondary h-full"
                  onClick={handleSubscribe}
                >
                  Subscribe!
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full border-t-2 border-secondary pt-10">
          <div id="social-media-hanles" className="flex w-[50%]">
            {icons.map((icon, index) => {
              return (
                <div
                  key={index}
                  className="flex md:h-[2rem] md:w-[2rem] lg:h-[2.5rem] lg:w-[2.5rem] 2xl:h-[2.9rem] 2xl:w-[2.9rem] bg-secondary rounded-full lg:p-2 mr-6 items-center justify-center"
                >
                  <div className="text-darkPrimary md:text-[1.25rem] lg:text-[1.75rem] 2xl:text-[2rem]">
                    {icon.icon}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-secondary font-bodyCopy w-[50%] leading-none mt-4 text-end md:text-[12px] lg:text-[16px] 2xl:text-[20px]">
            ©2025 Dauchi. All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export const Navlinks = ({ navList, useLink = false }) => {
  return (
    <div>
      {navList.map((item, index) => {
        return (
          <div>
            {useLink ? (
              <Link key={index} to={item.href}>
                <p className="text-white mt-1 md:text-[14px] lg:text-[16px] 2xl:text-[20px]">
                  {item.name}
                </p>
              </Link>
            ) : (
              <div key={index}>
                <p className="text-white  md:text-[14px] lg:text-[16px] 2xl:text-[20px] mt-1">
                  {item.name}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
